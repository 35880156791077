// import { CONDICION } from "../../../const/default";
// import { TRANSPORTE } from "../../../const/default";
// import { MONEDA } from "../../../const/default";
// import { LINEAS_PRODUCTO } from "../../../const/default";

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: null,
  condition: null,
  transport: null,
  coin: null,
  priceList: null,
  seller: null,
  idSeller: null,
  email: null,
  warehouse: null,
  observations: null,
  products: [],
  searchText: null,
};

const quotationSlice = createSlice({
  name: "quotation",
  initialState,
  reducers: {
    setPriceList: (state, action) => {
      state.priceList = action.payload;
    },
    setSeller: (state, action) => {
      state.seller = action.payload;
    },
    setObservations: (state, action) => {
      state.observations = action.payload;
    },
    setIdSeller: (state, action) => {
      state.idSeller = action.payload;
    },
    setEmailSeller: (state, action) => {
      state.email = action.payload;
    },
    setWarehouseSeller: (state, action) => {
      state.warehouse = action.payload;
    },
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setCondition: (state, action) => {
      state.condition = action.payload;
    },
    setTransport: (state, action) => {
      state.transport = action.payload;
    },
    setCoin: (state, action) => {
      state.coin = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    addProduct: (state, action) => {
      const { id } = action.payload;
      const productExists = state.products.some((element) => element.id === id);
      if (!productExists) {
        state.products.push({ ...action.payload });
      }
    },
    removeProduct: (state, action) => {
      state.products = state.products.filter(
        (product) => product.id !== action.payload
      );
    },
    updateProduct: (state, action) => {
      const index = state.products.findIndex(
        (product) => product.id === action.payload.id
      );
      if (index !== -1) {
        state.products[index] = { ...state.products[index], ...action.payload };
      }
    },
    resetQuotation: (state) => {
      return initialState;
    },
  },
});

export const {
  setClient,
  setCondition,
  setTransport,
  setCoin,
  setPriceList,
  setSeller,
  setObservations,
  setIdSeller,
  setEmailSeller,
  setWarehouseSeller,
  setSearchText,
  addProduct,
  removeProduct,
  updateProduct,
  resetQuotation,
} = quotationSlice.actions;
export default quotationSlice.reducer;
