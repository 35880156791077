import * as Yup from "yup";

export const ParameterSchema = Yup.object().shape({
  codigo: Yup.string().required("El código es requerido"),
  valor: Yup.string().required("El valor es requerido"),
  // tipo: Yup.string()
  //   .oneOf(["html", "texto"], "Tipo inválido")
  //   .required("El tipo es requerido"),
});

export const ParameterSchemaEdit = Yup.object().shape({
  codigo: Yup.string().required("El código es requerido"),
  valor: Yup.string().required("El valor es requerido"),
  // tipo: Yup.string()
  //   .oneOf(["importe", "json", "texto"], "Tipo inválido")
  //   .required("El tipo es requerido"),
});
