import React from "react";
import { Routes, Route } from "react-router-dom";
import { Login } from "./../pages/login";
import { Sellers } from "../pages/sellers";
import { Parameters } from "../pages/parameters";
import { HomePrivateDashboard } from "./../pages/dashboard";
import { Layout } from "./../components/Layout/Layout";
import { LayoutNavbar } from "../components/Layout/LayoutNavbar";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/login"
        element={
          <Layout>
            <Login />
          </Layout>
        }
      />
      <Route
        path="/dashboard"
        element={
          <LayoutNavbar>
            <HomePrivateDashboard />
          </LayoutNavbar>
        }
      />
      <Route
        path="/sellers"
        element={
          <LayoutNavbar>
            <Sellers />
          </LayoutNavbar>
        }
      />
      <Route
        path="/parameters"
        element={
          <LayoutNavbar>
            <Parameters />
          </LayoutNavbar>
        }
      />
    </Routes>
  );
};

export default AppRouter;
