import * as Yup from "yup";

export const SellerSchema = Yup.object().shape({
  email: Yup.string().email("Email inválido").required("El email es requerido"),
  password: Yup.string().required("La contraseña es requerida"),
  codigo_vendedor_profit: Yup.string().required(
    "El código de vendedor es requerido"
  ),
  almacen: Yup.string().required("El almacén es requerido"),
  lista_de_precio: Yup.array()
    .of(
      Yup.string().required(
        "Se requiere al menos un valor en la lista de precios"
      )
    )
    .min(1, "Lista de precio es requerida"),
  rol: Yup.string()
    .oneOf(["usuario", "administrador"])
    .required("El rol es requerido"),
});

export const SellerSchemaEdit = Yup.object().shape({
  email: Yup.string().email("Email inválido").required("El email es requerido"),
  codigo_vendedor_profit: Yup.string().required(
    "El código de vendedor es requerido"
  ),
  almacen: Yup.string().required("El almacén es requerido"),
  lista_de_precio: Yup.array()
    .of(
      Yup.string().required(
        "Se requiere al menos un valor en la lista de precios"
      )
    )
    .min(1, "Lista de precio es requerida"),
  rol: Yup.string()
    .oneOf(["usuario", "administrador"])
    .required("El rol es requerido"),
});
