import React, { useState, useEffect } from "react";
import { ParametersForm } from "./ParametersForm";
import { getAllParameters } from "../api/getAllParameters";

export const ListParameters = () => {
  const [parametros, setParametros] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedParametro, setSelectedParametro] = useState(null); // Estado para el parámetro seleccionado
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  // Cargar parámetros desde la API
  const loadParametros = async () => {
    setLoading(true);
    try {
      const result = await getAllParameters();
      setParametros(result.data ?? []);
    } catch (error) {
      console.error("Error al cargar parámetros:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadParametros();
  }, []);

  const handleAddParametro = () => {
    setSelectedParametro(null);
    setIsFormModalOpen(true);
  };

  const handleEditParametro = (parametro) => {
    setSelectedParametro(parametro); // Establecer el parámetro seleccionado para editar
    setIsFormModalOpen(true);
  };

  if (loading) return <div className="text-center p-4">Cargando...</div>;

  return (
    <div className="p-8 mt-8 bg-white border border-gray-200 rounded-md shadow-sm text-gray-800">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold mb-4">Parámetros</h2>
        <button
          type="button"
          onClick={handleAddParametro}
          className="inline-flex items-center justify-center px-3 py-1 text-md font-medium text-center text-white rounded-lg bg-gray-900 hover:bg-gray-700 focus:ring-4 focus:ring-blue-300"
        >
          Agregar Parámetro
        </button>
      </div>

      <table className="mt-8 min-w-full bg-white text-xs rounded-md">
        <thead className="border-b">
          <tr className="bg-gray-100 text-sm font-medium">
            <th className="p-3 text-left rounded-tl-md">ID</th>
            <th className="p-3 text-left">Código</th>
            <th className="p-3 text-left">Valor</th>
            <th className="p-3 rounded-tr-md">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {parametros.length > 0 ? (
            parametros.map((parametro) => (
              <tr key={parametro.id} className="hover:bg-gray-50 border-b">
                <td className="p-2">{parametro.id}</td>
                <td className="p-2">{parametro.codigo}</td>
                <td className="p-2">{parametro.valor}</td>
                <td className="p-2 flex items-center space-x-2 justify-center">
                  <button
                    onClick={() => handleEditParametro(parametro)}
                    className=" mt-1 border border-gray-700   bg-white py-1 px-2 rounded-md flex items-center space-x-1"
                  >
                    <span>Editar</span>
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr className="py-3 text-center">
              <td className="pt-6" colSpan={4}>
                No se encontraron registros
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <ParametersForm
        isOpen={isFormModalOpen}
        initialData={selectedParametro} // Pasar el parámetro seleccionado
        onClose={() => setIsFormModalOpen(false)}
        refresh={loadParametros}
      />
    </div>
  );
};
