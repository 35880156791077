import { configureStore } from "@reduxjs/toolkit";
import quotationReducer from "./features/quotation/quotationSlice";
import generalReducer from "./features/general/generalSlice";

export const store = configureStore({
  reducer: {
    quotation: quotationReducer,
    general: generalReducer,
  },
});

export default store;
