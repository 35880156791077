import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  FaUserCircle,
  FaUserFriends,
  FaShoppingCart,
  FaMoneyCheckAlt,
  FaCog,
  FaMoneyBillWave,
  FaChartLine,
  FaEllipsisV,
  FaSignOutAlt,
} from "react-icons/fa";
import menuItems from "./const/menuItems.json";

export const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAuthenticated = !!localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.general.user);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const iconMap = {
    FaUserTie: <FaUserFriends />,
    FaShoppingCart: <FaShoppingCart />,
    FaMoneyCheckAlt: <FaMoneyCheckAlt />,
    FaCog: <FaCog />,
    FaMoneyBillWave: <FaMoneyBillWave />,
    FaChartLine: <FaChartLine />,
  };

  const items = isAuthenticated ? menuItems.private : menuItems.public;

  return (
    <div className="flex h-screen">
      <aside className="w-64  bg-white border-r border-gray-300 p-4 relative">
        <ul className="space-y-2">
          <li className="mb-4">
            <span className="text-gray-900 ml-2 font-bold text-xl ">
              Artiglight Ventas
            </span>
          </li>
          {items.map((item, index) => {
            const isActive = location.pathname === item.path;
            return (
              <li key={index}>
                <Link
                  to={item.path}
                  className={`flex items-center p-2 rounded-md space-x-2 text-gray-700
                  ${
                    isActive
                      ? "bg-gray-100 text-gray-700"
                      : "hover:bg-gray-100 hover:text-gray-900"
                  }
                  `}
                >
                  {iconMap[item.icon]}
                  <span>{item.name}</span>
                </Link>
              </li>
            );
          })}

          {isAuthenticated && (
            <li className="absolute bottom-8">
              <div className="flex items-center justify-between space-x-2">
                <div className="flex items-center space-x-3">
                  <div className="rounded-full w-10 h-10 overflow-hidden">
                    <div className="rounded-full w-10 h-10 bg-gray-200 flex items-center justify-center">
                      <FaUserCircle className="w-6 h-6 text-gray-500" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-900 text-xs font-semibold">
                      {user.email}
                    </span>
                    <span className="text-gray-500 text-xs"> {user.rol}</span>
                  </div>
                </div>
                <button
                  aria-label="More actions"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={toggleModal}
                >
                  <FaEllipsisV />
                </button>
              </div>

              {isModalOpen && (
                <div className="absolute right-0 bottom-16 bg-white border border-gray-300 rounded-md shadow-md w-40 p-2 z-10">
                  <button
                    onClick={handleLogout}
                    className="flex items-center w-full text-left text-gray-700 hover:text-gray-900"
                  >
                    <FaSignOutAlt className="mr-2" />{" "}
                    <span className="text-sm">Cerrar sesión</span>
                  </button>
                </div>
              )}
            </li>
          )}
        </ul>
      </aside>
    </div>
  );
};
