import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useLogin } from "../hooks/useLogin";
import { LoginSchema } from "../const/schema";

export const LoginForm = () => {
  const { login } = useLogin();

  return (
    <div className="flex items-center justify-center p-4 mt-20">
      <div className="w-96 max-w-lg border border-gray-200 bg-white p-8 rounded-md shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
          Iniciar Sesión
        </h2>

        <Formik
          initialValues={{
            email: "elbacamposest@gmail.com",
            password: "Buenafe101*",
          }}
          validationSchema={LoginSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true);
              await login(values);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-6">
              <div>
                <label className="block font-semibold text-gray-700">
                  Correo Electrónico
                </label>
                <Field
                  type="email"
                  name="email"
                  className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block font-semibold text-gray-700">
                  Contraseña
                </label>
                <Field
                  type="password"
                  name="password"
                  className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <button
                type="submit"
                className="w-full inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Iniciando..." : "Iniciar Sesión"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
