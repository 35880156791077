import React from "react";
import { Navbar } from "../generals/Navbar/Navbar";

export const LayoutNavbar = ({ children }) => {
  return (
    <div className="flex h-screen">
      <Navbar />
      <main className="flex-1 p-8 overflow-auto">{children}</main>
    </div>
  );
};
