import React from "react";

export const Switch = ({ isChecked, onToggle }) => {
  return (
    <div
      onClick={onToggle}
      className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer transition-colors duration-300 ${
        isChecked ? "bg-green-500" : ""
      }`}
    >
      <div
        className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform ${
          isChecked ? "translate-x-6" : ""
        }`}
      ></div>
    </div>
  );
};
