import { getData } from "../../../const/apiHelper";

export const getAllParameters = async () => {
  try {
    const result = await getData("/parametros");
    return result;
  } catch (error) {
    console.error("Error al registrar el usuario:", error);
  }
};
