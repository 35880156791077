import axios from "axios";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACK_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();

    // Si la URL no es login, agregar el token
    if (token && !config.url.includes("/login")) {
      config.headers.Authorization = `Bearer ${token}`;
    } else if (!token && !config.url.includes("/login")) {
      console.warn("No se encontró el token");
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const postData = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    console.error(`Error en la solicitud POST: ${error}`);
    throw error;
  }
};

export const getData = async (url) => {
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error(`Error en la solicitud GET: ${error}`);
    throw error;
  }
};

export const putData = async (url, data) => {
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch (error) {
    console.error(`Error en la solicitud PUT: ${error}`);
    throw error;
  }
};

export const deleteData = async (url, data) => {
  try {
    const response = await axiosInstance.delete(url, data);
    return response.data;
  } catch (error) {
    console.error(`Error en la solicitud Delete: ${error}`);
    throw error;
  }
};
