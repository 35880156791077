import { useState } from "react";

export const useSearch = (data, searchKeys) => {
  const [searchText, setSearchText] = useState("");

  const filteredData = data.filter((item) =>
    searchKeys.some((key) =>
      (item[key] ?? "").toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return { filteredData, searchText, setSearchText };
};
