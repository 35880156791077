import { getData } from "../../../const/apiHelper";

export const getAllSellers = async () => {
  try {
    const result = await getData("/vendedores");
    return result;
  } catch (error) {
    console.error("Error al registrar el usuario:", error);
  }
};
