import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { X } from "react-feather";
import Select from "react-select";
import { listPrices } from "../const/data";
import { toast } from "react-toastify";
import { getAllWarehouses } from "../api/getAllWarehouses";
import { SellerSchema, SellerSchemaEdit } from "../const/schema";
import { createSellerApi } from "../api/createSellerApi";
import { updateSellerApi } from "../api/updateSellerApi";

export const SellerForm = ({
  isOpen,
  onClose,
  sellers = [],
  initialData = null,
  refresh,
}) => {
  const isEdit = !!initialData;

  const [warehouses, setWarehouses] = useState([]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const updatedValues = {
        ...values,
        lista_de_precio: Array.isArray(values.lista_de_precio)
          ? values.lista_de_precio.join(", ")
          : values.lista_de_precio,
      };

      if (isEdit) await updateSellerApi(updatedValues);
      else await createSellerApi(updatedValues);

      refresh();
      toast("Operación Exitosa");
    } catch (error) {
      console.error("Error al procesar:", error);
      toast.error("Error al procesar la operación");
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllWarehouses();
        setWarehouses(result.data ?? []);
      } catch (error) {
        console.error("Error al cargar vendedores:", error);
      }
    };

    fetchData();
  }, []);

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
        <div className="bg-white rounded-lg shadow-lg p-8 w-full h-auto max-w-xl relative">
          <button
            className="absolute top-2 right-3 text-gray-400 hover:text-gray-600"
            onClick={onClose}
          >
            <X size={24} className="text-gray-400" />
          </button>

          <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
            {isEdit ? "Editar Usuario" : "Registrar Usuario"}
          </h2>

          <Formik
            initialValues={{
              email: initialData?.email || "",
              password: "",
              codigo_vendedor_profit: initialData?.codigo_vendedor_profit || "",
              almacen: initialData?.almacen || "",
              lista_de_precio: initialData?.lista_de_precio || ["price_1"],
              rol: initialData?.rol || "usuario",
            }}
            validationSchema={isEdit ? SellerSchemaEdit : SellerSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-gray-700 font-medium">
                      Email
                    </label>
                    <Field
                      type="email"
                      name="email"
                      disabled={isEdit}
                      className="mt-1 p-1 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {!isEdit && (
                    <div>
                      <label className="block text-gray-700 font-medium">
                        Password
                      </label>
                      <Field
                        type="password"
                        name="password"
                        className="mt-1 p-1 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-gray-700 font-medium">
                      Código
                    </label>
                    <Field
                      as="select"
                      name="codigo_vendedor_profit"
                      className="mt-1 p-1 w-full border rounded-md"
                    >
                      <option value="">Seleccione...</option>
                      {sellers.map((item) => (
                        <option key={item.co_ven} value={item.co_ven}>
                          {item.co_ven} - {item.ven_des}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="codigo_vendedor_profit"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 font-medium">
                      Almacén
                    </label>
                    <Field
                      as="select"
                      name="almacen"
                      className="mt-1 p-1 w-full border rounded-md"
                    >
                      <option value="">Seleccione...</option>
                      {warehouses.map((almacen) => (
                        <option
                          key={almacen.co_sub}
                          value={almacen.co_sub.trim()}
                        >
                          {almacen.co_sub} - {almacen.des_sub}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="almacen"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700 font-medium">
                    Lista de Precios
                  </label>
                  <Field name="lista_de_precio">
                    {({ field, form }) => (
                      <Select
                        isMulti
                        options={listPrices}
                        className="mt-1"
                        value={listPrices.filter((option) =>
                          field.value.includes(option.value)
                        )}
                        onChange={(selected) =>
                          form.setFieldValue(
                            field.name,
                            selected.map((option) => option.value)
                          )
                        }
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="lista_de_precio"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div>
                  <label className="block text-gray-700 font-medium">Rol</label>
                  <Field
                    as="select"
                    name="rol"
                    className="mt-1 p-1 w-full border rounded-md"
                  >
                    <option value="usuario">Usuario</option>
                    <option value="administrador">Administrador</option>
                  </Field>
                  <ErrorMessage
                    name="rol"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 text-gray-600  bg-gray-200 rounded-lg"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="bg-gray-900 text-white p-2 px-4 rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-black"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Procesando..." : "Guardar"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  );
};
