import { getData } from "../../../const/apiHelper";

export const getAllWarehouses = async () => {
  try {
    const result = await getData("/almacenes");
    return result;
  } catch (error) {
    console.error("Error al consultar almacenes", error);
  }
};
