import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ConfirmationModal } from "../../../components/generals/ConfirmationModal/ConfirmationModal";
import { SellerForm } from "./SellerForm";
import { getAllSellers } from "../api/getAllSellers";
import { Switch } from "../../../components/generals/Switch";
import { usePagination } from "../hooks/usePagination";
import { useSearch } from "../hooks/useSearch";
import { deleteSellerApi } from "../api/deleteSellerApi";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

export const ListSellers = () => {
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedSellerData, setSelectedSellerData] = useState(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const { filteredData, searchText, setSearchText } = useSearch(sellers, [
    "ven_des",
    "user.email",
  ]);

  const {
    currentItems: currentSellers,
    totalPages,
    paginate,
    currentPage,
  } = usePagination(filteredData, 7);

  const loadSellers = async () => {
    setLoading(true);
    try {
      const result = await getAllSellers();
      setSellers(result.data ?? []);
    } catch (error) {
      console.error("Error al cargar vendedores:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (id) => {
    setSelectedSellerData(id);
    setConfirmationModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteSellerApi({ id: selectedSellerData });
      toast.success("Vendedor eliminado exitosamente");
      loadSellers();
    } catch (error) {
      toast.error("Error al eliminar el vendedor");
    } finally {
      setConfirmationModalOpen(false);
    }
  };

  useEffect(() => {
    loadSellers();
  }, []);

  const handleAddSeller = () => {
    setSelectedSellerData(null);
    setIsFormModalOpen(true);
  };

  const handleEdit = (seller) => {
    setSelectedSellerData({
      email: seller.user.email,
      codigo_vendedor_profit: seller.co_ven,
      almacen: seller.user.almacen,
      lista_de_precio: seller.user.lista_de_precio
        ? seller.user.lista_de_precio.split(", ")
        : [],
      rol: seller.user.rol,
    });
    setIsFormModalOpen(true);
  };

  if (loading) return <div className="text-center p-4">Cargando...</div>;

  return (
    <div className="p-8 mt-8 bg-white border border-gray-200 rounded-md shadow-sm text-gray-800">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold mb-4">Vendedores</h2>
        <button
          type="button"
          onClick={() => handleAddSeller(true)}
          className="inline-flex items-center justify-center px-3 py-1 text-md font-medium text-center text-white rounded-lg bg-gray-900 hover:bg-gray-700 focus:ring-4 focus:ring-blue-300"
        >
          Agregar Vendedor
        </button>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Buscar por nombre o correo"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="p-2 pl-4 bg-gray-100 rounded-full w-96"
        />
      </div>

      <table className="mt-8 min-w-full bg-white text-xs rounded-md">
        <thead className="border-b">
          <tr className="bg-gray-100 text-sm font-medium">
            <th className="p-3 text-left rounded-tl-md">Nombre</th>
            <th className="p-3 text-left">Código</th>
            <th className="p-3 text-left">Correo</th>
            <th className="p-3 text-left">Usario APP</th>
            <th className="p-3 rounded-tr-md">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentSellers.length > 0 ? (
            currentSellers
              .filter((seller) => seller?.user?.status !== "inactivo")
              .map((seller) => (
                <tr key={seller.id} className="hover:bg-gray-50 border-b">
                  <td className="p-2">{seller.ven_des}</td>
                  <td className="p-2">{seller.co_ven}</td>
                  <td className="p-2">{seller?.user?.email ?? ""}</td>
                  <td className="p-2">
                    <div className=" flex justify-center">
                      {seller?.user?.status === "activo" ? (
                        <FaCheckCircle className="text-green-500 h-5 w-20" />
                      ) : (
                        <FaTimesCircle className="text-gray-400 h-5 w-20" />
                      )}
                    </div>
                  </td>
                  <td className="p-2 flex items-center space-x-2 justify-center">
                    <button
                      onClick={() => handleEdit(seller)}
                      className=" mt-1 border border-gray-700   bg-white py-1 px-2 rounded-md flex items-center space-x-1"
                    >
                      <span>Editar</span>
                    </button>
                    <Switch
                      isChecked={seller?.user?.status === "activo"}
                      onToggle={() => handleDelete(seller.user.id)}
                    />
                  </td>
                </tr>
              ))
          ) : (
            <tr className="py-3 text-center">
              <td className="pt-6" colSpan={5}>
                No se encontraron registros
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {totalPages > 1 && (
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-3 py-1 bg-gray-200 text-gray-600 rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-300"
          >
            Anterior
          </button>
          <div className="text-sm">
            Página {currentPage} de {totalPages}
          </div>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-3 py-1 bg-gray-200 text-gray-600 rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-300"
          >
            Siguiente
          </button>
        </div>
      )}

      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={confirmDelete}
        message="¿Estás seguro de que deseas desactivar a este vendedor?"
      />

      <SellerForm
        isOpen={isFormModalOpen}
        sellers={sellers}
        seller={selectedSellerData}
        onClose={() => setIsFormModalOpen(false)}
        initialData={selectedSellerData}
        refresh={loadSellers}
      />
    </div>
  );
};
