import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    resetGeneral: (state) => {
      return initialState;
    },
  },
});

export const { setUser, resetGeneral } = generalSlice.actions;
export default generalSlice.reducer;
