import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postData } from "../../../const/apiHelper";
import { toast } from "react-toastify";
import { setUser } from "../../../store/features/general/generalSlice";

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login = async (data) => {
    try {
      const response = await postData("/login", data);

      if (response.success && response.token) {
        localStorage.setItem("token", response.token);
        toast.success("Inicio de sesión exitoso");
        dispatch(setUser(response.user));
        navigate("/dashboard");
      } else {
        toast.error("Credenciales incorrectas. Por favor, intenta nuevamente.");
        throw new Error("No se recibió un token válido.");
      }
    } catch (error) {
      toast.error("Credenciales incorrectas. Por favor, intenta nuevamente.");
      throw error;
    }
  };

  return { login };
};
