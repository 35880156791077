import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { X } from "react-feather";
import { createParameterApi } from "../api/createParameterApi";
import { updateParameterApi } from "../api/updateParameterApi";
import { ParameterSchema, ParameterSchemaEdit } from "../const/schema";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html"; // Importar la función stateToHTML
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const ParametersForm = ({
  isOpen,
  onClose,
  refresh,
  initialData = null,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty()); // Estado inicial del editor

  const isEdit = !!initialData;

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      if (isEdit) {
        await updateParameterApi({ ...values, id: initialData.id });
        toast.success("Parámetro actualizado exitosamente");
      } else {
        await createParameterApi(values);
        toast.success("Parámetro creado exitosamente");
      }
      refresh();
      onClose();
    } catch (error) {
      toast.error("Error al procesar la operación");
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (isEdit && initialData) {
      const contentState = stateFromHTML(initialData.valor);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [isEdit, initialData]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full h-auto max-w-xl relative">
        <button
          className="absolute top-2 right-3 text-gray-600 hover:text-gray-900"
          onClick={onClose}
          aria-label="Cerrar"
        >
          <X size={24} className="text-gray-600" />
        </button>
        <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
          {isEdit ? "Editar Parámetro" : "Agregar Parámetro"}
        </h2>
        <Formik
          initialValues={{
            codigo: initialData?.codigo || "",
            valor: "", // El valor se actualizará dinámicamente
          }}
          validationSchema={isEdit ? ParameterSchemaEdit : ParameterSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="mb-4">
                <label className="block text-sm font-medium">Código</label>
                <Field
                  type="text"
                  name="codigo"
                  className="p-2 pl-4 bg-gray-100 rounded-lg w-full"
                />
                <ErrorMessage
                  name="codigo"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Editor de Texto - React Draft WYSIWYG */}
              <div className="mb-4">
                <label className="block text-sm font-medium">Valor</label>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={(state) => {
                    setEditorState(state);
                    const contentState = state.getCurrentContent(); // Obtener el ContentState directamente
                    const htmlContent = stateToHTML(contentState); // Convertir a HTML usando ContentState
                    setFieldValue("valor", htmlContent); // Establecer el HTML en el formulario
                  }}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontSize",
                      "list",
                      "textAlign",
                      "history",
                    ],
                  }}
                />
                <ErrorMessage
                  name="valor"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-gray-600 bg-gray-200 rounded-lg"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-gray-900 text-white p-2 px-4 rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-black"
                  disabled={isSubmitting}
                >
                  {isSubmitting
                    ? "Procesando..."
                    : isEdit
                    ? "Actualizar"
                    : "Guardar"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
